<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Study Areas</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Study Areas
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('academic-program-category', 'create')"
                      @click="manageCategory({}, 'open')"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon elevation="2" outlined small>fas fa-plus</v-icon>&nbsp;
                    Add Study Areas
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-text-field
                      @input="search.title = $event !== null ? $event : ''"
                      clearable
                      dense
                      label="Name"
                      outlined
                      v-model="search.title"
                      v-on:keyup.enter="getAcademicProgramCategory"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" sm="12">
                  <v-select
                      :items="status"
                      @input="search.is_active = $event !== null ? $event : ''"
                      clearable
                      dense
                      item-text="name"
                      item-value="value"
                      label="Status"
                      outlined
                      v-model="search.is_active"
                      v-on:keyup.enter="getAcademicProgramCategory"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                  <v-btn
                      :loading="loading"
                      @click.prevent="getAcademicProgramCategory"
                      class="mt-1 btn btn-primary text-white text-left"
                  >
                    <v-icon elevation="2" outlined small>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  type="table-row-divider@25"
                  v-if="loading"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Title</strong></th>
                  <th><strong>Icons</strong></th>
                  <th><strong>Is Featured</strong></th>
                  <th><strong>Status</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template v-if="categories && categories.length>0">
                  <tr v-for="(category, index) of categories" :key="index">
                    <td>{{ category.title }}</td>
                    <td>{{ category.icons }}</td>
                    <td>
                      <i class="fas" :class="category.is_featured ? 'fa-check text-success' : 'fa-ban text-danger'"></i>
                    </td> <td>
                      <i class="fas" :class="category.is_active? 'fa-check text-success' : 'fa-ban text-danger'"></i>
                    </td>
                    <td>
                      <template>
                        <b-dropdown
                            no-caret
                            no-flip
                            right
                            size="sm"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            variant="link"
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('academic-program-category', 'edit')">
                              <a @click="manageCategory(category, 'open')" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text class="navi-item" tag="div" v-if="checkIsAccessible('academic-program-category', 'delete')">
                              <a @click.prevent="deleteCategory(category)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="50" class="font-size-lg">
                      No data found
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  :disabled="loading"
                  :per-page="perPage"
                  :total-rows="total"
                  @input="getAcademicProgramCategory"
                  class="pull-right mt-7"
                  first-number
                  last-number
                  v-if="categories.length > 0"
                  v-model="page"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <create-or-update
        ref="academic-program-category"
        :dialog="category_dialog" @close_dialog="manageCategory({}, 'close')"></create-or-update>
  </v-app>
</template>

<script>
import AcademicProgramCategoryService from "@/services/academic/program/category/AcademicProgramCategoryService";
import CreateOrUpdate from "./CreateOrUpdate";

const categoryService = new AcademicProgramCategoryService();

export default {
  name: "Program-Index",
  components: {
    CreateOrUpdate
  },
  data() {
    return {
      search: {},
      categories: [],
      category_dialog: false,
      loading: false,
      total: null,
      perPage: null,
      page: null,
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
    }
  },
  mounted() {
    this.getAcademicProgramCategory();
  },
  computed: {},
  methods: {
    getAcademicProgramCategory() {
      categoryService.paginate(this.search).then(response => {
        this.categories = response.data.data
      }).catch(error => {
        this.categories = [];
      })
    },
    manageCategory(category = {}, type) {
      if (type == 'open') {
        if(category.id)
        this.$refs['academic-program-category'].assignCategory(category)
        this.category_dialog = true
      } else {
        this.category_dialog = false
        this.getAcademicProgramCategory();
      }
    },
    deleteCategory(category) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            categoryService.delete(category.id).then(response => {
              this.$snotify.success("Study Areas has been deleted successfully")
              this.getAcademicProgramCategory();
            }).catch(error => {
              this.$snotify.error("Something went wrong please try again later")
            }).finally(this.loading = false)
          }
        }
      });
    }
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>>