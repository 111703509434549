<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title>
        {{ title }} Study Areas
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="category.title" placeholder="Title" outlined dense
                          :error="$v.category.title.$error"
            ></v-text-field>
            <span class="text-danger" v-if="$v.category.title.$error">This field is required</span>
          </v-col>
          <v-col cols="12">
            <label for="">Icons</label>
            <br>
            <v-btn class="my-1" @click="removeIcon" :ripple="false" icon v-if="category.icons">
              <v-icon class="mx-5">
                {{category.icons}}
              </v-icon>
            </v-btn>
            <vue-awesome-icon-picker class="mb-3"  v-model="category.icons" :icon-preview="false" @selected="onIconSelected" placeholder="Icons" />
            <span class="mt-3 font-size-base text-danger">* To Remove Icon. Click On Icon</span>
          </v-col>
          <v-col cols="12">
            <ckeditor
                :config="editorConfig"
                v-model="category.description"
            ></ckeditor>
          </v-col>
          <v-col cols="6" md="6">
            Is Featured
            <v-switch :label="category.is_featured ? 'Yes' : 'No'" v-model="category.is_featured"></v-switch>
          </v-col>
          <v-col cols="6" md="6">
            Status
            <v-switch :label="category.is_active ? 'Active' : 'InActive'" v-model="category.is_active"></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="mr-2" text dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            class="btn btn-primary"
            medium
            @click="createOrUpdate()"
            :loading="loading"
            v-if="checkIsAccessible('academic-program-category', 'create') || checkIsAccessible('academic-program-category', 'edit')"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcademicProgramCategoryService from "@/services/academic/program/category/AcademicProgramCategoryService";
import {required} from "vuelidate/lib/validators";

const categoryService = new AcademicProgramCategoryService();
import VueAwesomeIconPicker from '@rightbraintechbd/vue-awesome-icon-picker';
export default {
  props: ['dialog'],
  name: "CreateOrUpdate",
  components: {
    'vue-awesome-icon-picker': VueAwesomeIconPicker
  },
  validations: {
    category: {
      title: {required}
    }
  },
  data() {
    return {
      loading: false,
      edit: false,
      title: "Create",
      category: {
        title: null,
        icons: '',
        is_featured: false,
        is_active: true
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
    }
  }, methods: {
    removeIcon() {
      this.category.icons = '';
    },
    createOrUpdate() {
      this.$v.category.$touch();
      if (this.$v.category.$error) {
        setTimeout(() => {
          this.$v.category.$reset();
        }, 3000)
      } else {
        if (this.edit) {
          this.update();
        } else {
          this.create();
        }
      }
    }, update() {
      this.loading = true
      categoryService.update(this.category.id, this.category).then(response => {
        this.$snotify.success("Study Areas has been created successfully")
        this.closeDialog();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later");
      }).finally(this.loading = false)
    }, create() {
      this.loading = true
      categoryService.create(this.category).then(response => {
        this.$snotify.success("Study Areas has updated successfully")
        this.closeDialog();
      }).catch(error => {
        this.$snotify.error("Something went wrong please try again later");
      }).finally(this.loading = false)
    },
    onIconSelected(icon) {
      this.category.icons = `${icon.type} fa-${icon.name}`;
    },
    closeDialog() {
      this.category = {
        title: null,
        icons: '',
        is_featured: false,
        is_active: true
      }
      this.$v.category.$reset();
      this.edit = false;
      this.$emit('close_dialog');
    },
    assignCategory(category) {
      this.category = category;
      this.edit = true
    }

  }
}
</script>

<style lang="scss">
.v-btn::before {
  background-color: transparent;
}
</style>
